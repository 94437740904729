if (window.customCollapseInitialized === undefined) {
  window.customCollapseInitialized = true

  window.toggleCollapse = function (domId) {
    const currentContainer = document.getElementById(domId);
    const isOpen = currentContainer.getAttribute('data-collapse') === 'true'

    // Si le collapse actuel est fermé, fermez tous les autres avant de l'ouvrir.
    if (!isOpen) {
      const allCollapses = document.querySelectorAll('.collapse-container[data-collapse="true"]')
      allCollapses.forEach(container => {
        if (container.id !== domId) {
          const otherContent = container.querySelector('.collapse-content')
          const otherArrow = container.querySelector('.collapse-arrow')
          otherContent.classList.add('hidden')
          otherArrow.style.transform = 'rotate(0deg)'
          container.setAttribute('data-collapse', 'false')
        }
      });
    }

    // Basculer l'état du collapse actuel
    const content = currentContainer.querySelector('.collapse-content')
    const arrow = currentContainer.querySelector('.collapse-arrow')
    if (isOpen) {
      content.classList.add('hidden')
      arrow.style.transform = 'rotate(0deg)'
      currentContainer.setAttribute('data-collapse', 'false')
    } else {
      content.classList.remove('hidden')
      arrow.style.transform = 'rotate(180deg)'
      currentContainer.setAttribute('data-collapse', 'true')


      // fix scroll
      const yOffset = -150
      const elementRect = currentContainer.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.pageYOffset
      const top = absoluteElementTop + yOffset

      window.scrollTo({
        top: top,
        behavior: 'smooth'
      })
    }
  }

  document.addEventListener('DOMContentLoaded', function () {
    const openCollapses = document.querySelectorAll('.collapse-container[data-collapse="true"]')

    openCollapses.forEach(container => {
      const content = container.querySelector('.collapse-content')
      const arrow = container.querySelector('.collapse-arrow')

      content.classList.remove('hidden')
      arrow.style.transform = 'rotate(180deg)'
    });
  });
}
